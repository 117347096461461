/** @jsx jsx */
import { jsx } from "theme-ui"
import Layout from "../../../../node_modules/@lekoarts/gatsby-theme-minimal-blog/src/components/layout"
import Hero from "../texts/hero.mdx"

const Homepage = () => {

  return (
<p>Down for maintenance ;&#41;</p>
  )
}

export default Homepage
